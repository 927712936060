.form {
	&-group {
		@apply py-1;

		label {
			@apply block leading-7 font-medium text-sm capitalize text-primary-500/80;

			p {
				@apply normal-case;
			}
		}

		input,
		select,
		textarea {
			@apply h-[40px] w-full px-4 text-base rounded-md outline-none placeholder-shown:placeholder-gray-600 ring-1 text-gray-800 placeholder-shown:placeholder-opacity-60 placeholder-shown:font-normal  focus:ring-2 ring-primary-500/30 focus:ring-primary-500/80 disabled:ring-primary-500/20 disabled:text-gray-800/50 disabled:bg-gray-100 disabled:cursor-not-allowed;
		}

		textarea {
			@apply min-h-[100px] h-auto py-1;
		}

		input[type='submit'] {
			@apply text-white w-auto px-8;
		}
	}

	&-group-settings {
		@apply py-3 px-0;

		label {
			@apply inline-block pb-1 text-sm font-medium uppercase text-primary-500/70;

			p {
				@apply normal-case;
			}
		}

		input,
		select,
		textarea {
			@apply h-[40px] w-full px-4 text-base outline-none rounded placeholder-shown:placeholder-gray-600 ring-1 text-gray-800 placeholder-shown:placeholder-opacity-60 placeholder-shown:font-normal  focus:ring-2 ring-primary-500/30 focus:ring-primary-500/80 disabled:ring-primary-500/20 disabled:text-gray-800/50 disabled:bg-gray-100 disabled:cursor-not-allowed;
		}

		textarea {
			@apply min-h-[100px] h-auto py-1;
		}

		input[type='submit'] {
			@apply text-white w-auto px-8;
		}
	}
}

input,
select,
textarea {
	/*Input Autofill Background Change*/
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out, color 5000s ease-in-out !important;
		-webkit-text-fill-color: #333 !important;
	}
}
